<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Ubah Karyawan'">
          <template v-slot:body>
            <HrForm :form="form" :route="'employees/'+ form.id" :password="false"/>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import HrForm from '@/component/human-resource/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import module from '@/core/modules/CrudModule.js'

export default {

  data(){
    return {
      form:{
        email: "",
        name: "",
        birt_place: "",
        birt_date: "",
        address: "",
        phone: "",
        mobile_phone: "",
        id_card_number: "",
        employee_number_id:"",
        password: "",
        password_confirmation: "",
        position_id:"",
        depertment_id:"",
        photo: [],
        photo_name: "/images/default-profile.svg"
      }
    }
  },

  name: 'Edit',

  components: {
    Card,
    HrForm
  },

   methods: {

   async get() {
      this.form = await module.get('employees/' + this.$route.params.id)

      if (this.form == null) {
        this.$route.push('/employee/list')
      } else {
        this.form.photo_name = this.form.photo
        this.form.photo = []
        this.form['_method'] = "PUT"
        this.dataLoaded = true
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Karyawan" },
      { title: "Daftar Karyawan", route: "/human-resource/employee/list" },
      { title: "Ubah Karyawan" },
    ])
    this.get()
  },

}
</script>